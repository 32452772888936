import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/HomePage.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/TransitionEffect.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/development-amico.webp");
